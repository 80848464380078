<template>
  <SignComponent
    title="Forgot your password?"
    subTitle="Please enter your email to proceed."
  >
    <div class="d-flex flex-column align-center card-body">
      <v-alert outlined text class="mt-3" type="success" v-if="submitted">
        A link to reset your password has been successfully sent to
        {{ recoveryForm.email }}
      </v-alert>

      <v-form
        v-model="valid"
        @submit.prevent="handleRecoverySubmit"
        class="search-filter-wrapper"
      >
        <v-text-field
          outlined
          dense
          label="Email"
          placeholder="example@gmail.com"
          v-model="recoveryForm.email"
          :rules="emailRules"
        />
        <div class="mt-8 d-flex flex-column align-center">
          <v-btn
            color="green"
            class="px-4 mb-4 white--text"
            @click.prevent="handleRecoverySubmit"
            :disabled="!valid || loading || submitted"
            :loading="loading || submitted"
          >
            Recover account
          </v-btn>

          <v-btn text class="p-0" :disabled="submitted" to="/login"
            >Back to login</v-btn
          >
        </div>
      </v-form>
    </div>
    <div class="d-flex justify-center" v-if="isChina">
      {{ icpNotice }}
    </div>
  </SignComponent>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SignComponent from "@/components/SignComponent";
import { isChina, icpNotice } from "@/helpers/variables";

export default {
  name: "ForgotPassword",
  components: { SignComponent },
  data() {
    return {
      recoveryForm: {
        email: null,
      },
      submitted: false,
      valid: false,
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+/.test(v) || "Email must be valid",
      ],
      isChina,
      icpNotice,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.user.loading.forgotPassword,
    }),
  },
  methods: {
    ...mapActions({
      forgotPassword: "user/forgotPassword",
    }),
    async handleRecoverySubmit() {
      if (await this.forgotPassword(this.recoveryForm.email)) {
        this.submitted = true;
        setTimeout(() => {
          this.$router.push({
            path: "/login",
          });
        }, 5000);
      }
    },
  },
};
</script>

<style lang="scss">
.login-page .v-alert__content {
  max-width: 250px;
}
</style>
